import * as React from 'react';
import { FieldValidationStateHandler, FormValidationState } from '../schemes';

export const useValidationState: (
	initialState: FormValidationState
) => [FormValidationState, FieldValidationStateHandler] = initialState => {
	const [formValidationState, setFormValidationState] = React.useState(initialState);

	const onFieldStateChange: FieldValidationStateHandler = (fieldName, validationState) => {
		const oldFormValidationState = { ...formValidationState };
		oldFormValidationState.fields[fieldName] = { ...validationState };

		setFormValidationState(Object.assign({}, oldFormValidationState));
	};

	return [formValidationState, onFieldStateChange];
};
