export const correctTicketNumber = {
	regex: /^[0-9A-ZА-Я]{13,13}$/,
	error: 'ticketNumberError'
};

export const correctLastName = {
	regex: /^[a-zA-Zа-яА-Я]{2,30}$/,
	error: 'lastNameError'
};

export const correctPnrOrTicketNumber = {
	regex: /(^[0-9БВГДКЛМНПРСТФХЦЖШBVGDKLMNPRSTFXCZW]{6,6}$)|(^[0-9A-ZА-Я]{13,13}$)/,
	error: 'pnrOrTicketNumberError'
};
