import * as React from 'react';
import { Fields, FieldValidationStateHandler } from '../../schemes';

type ValidationRule = { regex: RegExp; error?: string };
type ValidationRulesArr = ValidationRule[];

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
	fieldName?: Fields;
	onValidationStateChange?: FieldValidationStateHandler;
	validation?: ValidationRulesArr;
}

const Index: React.FunctionComponent<Props> = ({ validation, fieldName, onValidationStateChange, ...props }) => {
	const [isValid, setIsValid] = React.useState(true);

	const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		const value = e.target.value;

		if (props.onChange) {
			props.onChange(e);
		}

		if (!validation) {
			return;
		}

		for (let [index, validationRule] of Object.entries(validation)) {
			if (validationRule.regex.test(value) === false && validationRule.error) {
				if (onValidationStateChange && typeof fieldName !== 'undefined') {
					onValidationStateChange(fieldName, { valid: false, error: validationRule.error });
				}
				setIsValid(false);
				return;
			}
		}

		if (onValidationStateChange && typeof fieldName !== 'undefined') {
			onValidationStateChange(fieldName, { valid: true, error: false });
		}
		setIsValid(true);
	};

	return <input data-valid={isValid} onChange={onChange} {...props} />;
};

export default Index;
