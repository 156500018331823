export enum Language {
	English = 'en',
	Russian = 'ru',
	German = 'de',
	Romanian = 'ro',
	Kazakh = 'kk',
	Uzbek = 'uz',
	Ukrainian = 'uk'
}

export enum ApplicationMode {
	NEMO = 'NEMO',
	WEBSKY = 'WEBSKY'
}

export enum TabsState {
	BuyTicket,
	Registration,
	SearchOrder,
	Login
}

export interface FieldValidationState {
	valid: boolean;
	error: string | boolean;
}

export enum Fields {
	TicketNumber,
	LastName
}

export interface FormValidationState {
	fields: { [key in Fields]: FieldValidationState };
}

export type FieldValidationStateHandler = (fieldName: Fields, validationState: FieldValidationState) => void;
