import * as React from 'react';

export const ticketIcon = (
	<svg
		className="widget__icon icon_ticket"
		height="1792"
		viewBox="0 0 1792 1792"
		width="1792"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1024 452l316 316-572 572-316-316zm-211 979l618-618q19-19 19-45t-19-45l-362-362q-18-18-45-18t-45 18l-618 618q-19 19-19 45t19 45l362 362q18 18 45 18t45-18zm889-637l-907 908q-37 37-90.5 37t-90.5-37l-126-126q56-56 56-136t-56-136-136-56-136 56l-125-126q-37-37-37-90.5t37-90.5l907-906q37-37 90.5-37t90.5 37l125 125q-56 56-56 136t56 136 136 56 136-56l126 125q37 37 37 90.5t-37 90.5z"/>
	</svg>
);

export const searchIcon = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M3.624,15a8.03,8.03,0,0,0,10.619.659l5.318,5.318a1,1,0,0,0,1.414-1.414l-5.318-5.318A8.04,8.04,0,0,0,3.624,3.624,8.042,8.042,0,0,0,3.624,15Zm1.414-9.96a6.043,6.043,0,1,1-1.77,4.274A6,6,0,0,1,5.038,5.038Z"/></svg>
);

export const aviaIcon = (
	<svg
		className="widget__icon icon_avia"
		contentScriptType="text/ecmascript"
		contentStyleType="text/css"
		enableBackground="new 0 0 2048 2048"
		height="2048px"
		id="Layer_1"
		preserveAspectRatio="xMidYMid meet"
		version="1.1"
		viewBox="-0.6669999957084656 0 1410.0 2048"
		width="1410.0px"
		xmlSpace="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		zoomAndPan="magnify"
	>
		<path
			d="M1376,288c29.333,34.667,33.333,84,12,148s-57.333,121.333-108,172l-161,161l160,696c3.333,12.667-0.667,23.667-12,33  l-128,96c-4.667,4-11,6-19,6c-2.667,0-5-0.333-7-1c-10-2-17-7.333-21-16l-279-508l-259,259l53,194c3.333,11.333,0.667,21.667-8,31  l-96,96c-6,6-13.667,9-23,9h-2c-10-1.333-18-5.667-24-13l-189-252L13,1210c-7.333-4.667-11.667-12.333-13-23  c-0.667-8.667,2.333-17,9-25l96-97c6-6,13.667-9,23-9c4,0,6.667,0.333,8,1l194,53l259-259L81,572c-9.333-5.333-15-13.333-17-24  c-1.333-10.667,1.667-19.667,9-27l128-128c9.333-8.667,19.333-11.333,30-8l665,159l160-160c50.667-50.667,108-86.667,172-108  S1341.333,258.667,1376,288z"/>
	</svg>
);

export const userIcon = (
	<svg
		enableBackground="new 0 0 32 32"
		height="32px"
		id="svg2"
		version="1.1"
		viewBox="0 0 32 32"
		width="32px"
		xmlSpace="preserve"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="background">
			<rect fill="none" height="32" width="32"/>
		</g>
		<g id="user_x5F_close_x5F_edit">
			<path
				d="M32,23c-0.002-4.973-4.029-9-9-9c-0.025,0-0.049,0.003-0.074,0.004C23.608,12.826,24,11.459,24,10c0-4.418-3.582-8-8-8   c-4.418,0-8,3.582-8,8c0,4.26,3.332,7.732,7.531,7.977c-0.419,0.622-0.753,1.304-1.008,2.023H4c-4,0-4,4-4,4v8h32v-8   C32,24,32,23.207,32,23z M29.883,23c-0.009,3.799-3.084,6.874-6.883,6.883c-3.801-0.009-6.876-3.084-6.885-6.883   c0.009-3.801,3.084-6.876,6.885-6.884C26.799,16.124,29.874,19.199,29.883,23z"/>
			<polygon points="22,27 19,27 19,24  "/>
			<rect
				height="4.243"
				transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 56.5269 20.5858)"
				width="7.071"
				x="20.464"
				y="19.879"
			/>
		</g>
	</svg>
);

export const loginIcon = (
	<svg
		enableBackground="new 0 0 32 32"
		height="32px"
		id="svg2"
		version="1.1"
		viewBox="0 0 32 32"
		width="32px"
		xmlSpace="preserve"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="background">
			<rect fill="none" height="32" width="32"/>
		</g>
		<g id="user_x5F_close_x5F_edit">
			<path
				d="M32,23c-0.002-4.973-4.029-9-9-9c-0.025,0-0.049,0.003-0.074,0.004C23.608,12.826,24,11.459,24,10c0-4.418-3.582-8-8-8   c-4.418,0-8,3.582-8,8c0,4.26,3.332,7.732,7.531,7.977c-0.419,0.622-0.753,1.304-1.008,2.023H4c-4,0-4,4-4,4v8h32v-8   C32,24,32,23.207,32,23z M29.883,23c-0.009,3.799-3.084,6.874-6.883,6.883c-3.801-0.009-6.876-3.084-6.885-6.883   c0.009-3.801,3.084-6.876,6.885-6.884C26.799,16.124,29.874,19.199,29.883,23z"/>
			<polygon points="22,27 19,27 19,24  "/>
			<rect
				height="4.243"
				transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 56.5269 20.5858)"
				width="7.071"
				x="20.464"
				y="19.879"
			/>
		</g>
	</svg>
);
