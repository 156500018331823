import * as React from 'react';
import Input from '../Common/Input';
import { Fields, FieldValidationState } from '../schemes';
import { useValidationState, correctTicketNumber, correctLastName } from '../utils';
import cn from 'classnames';
import { i18n } from '../i18n';

import './checkinForm.scss';

export interface Props {}

const CheckinForm: React.FunctionComponent<Props> = ({}) => {
	const [formValidationState, onFieldStateChange] = useValidationState({
		fields: {
			[Fields.LastName]: {
				error: false,
				valid: false
			},
			[Fields.TicketNumber]: {
				error: false,
				valid: false
			}
		}
	});

	const ticketNumberValidationState: FieldValidationState = formValidationState.fields[Fields.TicketNumber];
	const lastNameValidationState: FieldValidationState = formValidationState.fields[Fields.LastName];
	const isFormValid = ticketNumberValidationState.valid && lastNameValidationState.valid;

	return (
		<div className="checkin-form">
			<h4 className="checkin-form__heading">{i18n('checkinHeading')}</h4>
			<form action="https://booking.uvtaero.ru/websky_checkin" className="checkin-form__form">
				<label className="checkin-form__label">
					{ticketNumberValidationState.error && (
						<span className="checkin-form__tooltip">
							{i18n(ticketNumberValidationState.error as string)}
						</span>
					)}
					<Input
						fieldName={Fields.TicketNumber}
						onValidationStateChange={onFieldStateChange}
						name="ticketNumber"
						className={cn('checkin-form__input', {
							'checkin-form__input_error': ticketNumberValidationState.error
						})}
						placeholder={i18n('ticketNumberPlaceholder')}
						validation={[correctTicketNumber]}
					/>
				</label>
				<label className="checkin-form__label">
					{lastNameValidationState.error && (
						<span className="checkin-form__tooltip">{i18n(lastNameValidationState.error as string)}</span>
					)}
					<Input
						onValidationStateChange={onFieldStateChange}
						fieldName={Fields.LastName}
						name="lastName"
						className={cn('checkin-form__input', {
							'checkin-form__input_error': lastNameValidationState.error
						})}
						placeholder={i18n('lastNamePlaceholder')}
						validation={[correctLastName]}
					/>
				</label>
				<input
					className={cn('checkin-form__submit', { 'checkin-form__submit_disabled': !isFormValid })}
					disabled={!isFormValid}
					type="submit"
					value={i18n('goToCheckin')}
				/>
			</form>
		</div>
	);
};

export default CheckinForm;
