import * as React from 'react';

import * as SearchWidget from '@nemo.travel/search-widget';
import '@nemo.travel/search-widget/dist/flights.search.widget.min.css';
import './flightsSearch.scss';
import { i18n } from '../i18n';

const FlightsSearchComponent: React.FunctionComponent<SearchWidget.SystemState> = props => {
	return (
		<div className="flights-search">
			<div className="flights-search__widget-wrp">
				<h4 className="flights-search__heading">{i18n('searchTicketHeading')}</h4>
				<SearchWidget.Component {...props} />
			</div>
		</div>
	);
};

export default FlightsSearchComponent;
