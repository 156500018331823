import * as React from 'react';
import Tab from '../Tab/Tab';
import { aviaIcon, ticketIcon, userIcon, loginIcon, searchIcon } from '../Icons';
import { TabsState } from '../schemes';
import './tabs.scss';

export interface Props {
	tabsState: TabsState;
	handleClick: (tab: TabsState) => void;
	locale?: string;
}

const Tabs: React.FunctionComponent<Props> = ({ tabsState, handleClick, locale }) => {
	return (
		<div className="tabs">
			<Tab
				value={TabsState.BuyTicket}
				active={TabsState.BuyTicket === tabsState}
				tooltip={'searchTicketHeading'}
				onClick={handleClick}
			>
				{aviaIcon}
			</Tab>
			<Tab
				value={TabsState.Registration}
				active={TabsState.Registration === tabsState}
				tooltip={'checkinTabTooltip'}
				onClick={handleClick}
			>
				{ticketIcon}
			</Tab>
			<Tab
				value={TabsState.SearchOrder}
				active={TabsState.SearchOrder === tabsState}
				tooltip={'searchOrderTabTooltip'}
				onClick={handleClick}
			>
				{searchIcon}
			</Tab>
			<Tab
				value={TabsState.Login}
				active={TabsState.Login === tabsState}
				tooltip={'loginTooltip'}
				onClick={handleClick}
				locale={locale}
			>
				{loginIcon}
			</Tab>
		</div>
	);
};

export default Tabs;
