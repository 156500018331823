import * as React from 'react';
import FlightsSearchComponent from './FlightsSearch/FlightsSearchComponent';
import { TabsState } from './schemes';
import { SystemState } from '@nemo.travel/search-widget';
import './app.scss';
import Tabs from './Tabs';
import SearchOrder from './SearchOrder';
import CheckinForm from './CheckinForm';

const App: React.FunctionComponent<SystemState> = props => {
	const [tabsState, changeTabsState] = React.useState(TabsState.BuyTicket);
	const handleClick = changeTabsState;

	return (
		<div className="App">
			<Tabs handleClick={handleClick} tabsState={tabsState} locale={props.locale} />
			{tabsState === TabsState.BuyTicket && <FlightsSearchComponent {...props} />}
			{tabsState === TabsState.Registration && <CheckinForm />}
			{tabsState === TabsState.SearchOrder && <SearchOrder />}
			{tabsState === TabsState.Login}
		</div>
	);
};

export default App;
