import * as React from 'react';
import cx from 'classnames';
import { TabsState } from '../schemes/';
import './tab.scss';
import { i18n } from '../i18n';

export interface Props {
	value: TabsState;
	tooltip?: string;
	onClick: (tab: TabsState) => void;
	active: boolean;
	locale?: string;
}

const Tab: React.FunctionComponent<Props> = ({ onClick, active, value, tooltip, ...props }) => {
	return (
		<div
			onClick={() => {
				value === TabsState.Login
					? (window.location.href = `https://booking.uvtaero.ru/websky/?showLoginPopup=1&lang=${
							props.locale
					  }#/search`)
					: onClick(value);
			}}
			className={cx('tab', { tab_active: active })}
			{...props}
		>
			{tooltip && <span className="tab__tooltip">{i18n(tooltip)}</span>}
			<span className="tab__icon">{props.children}</span>
			{tooltip && <span className="tab__name">{i18n(tooltip)}</span>}
		</div>
	);
};

export default Tab;
