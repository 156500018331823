import * as React from 'react';
import cn from 'classnames';
import Input from '../Common/Input';
import './searchOrder.scss';
import { i18n } from '../i18n';
import { Fields, FieldValidationState } from '../schemes';
import { correctLastName, correctPnrOrTicketNumber, useValidationState } from '../utils';

export interface Props {}

const SearchForm: React.FunctionComponent<Props> = ({}) => {
	const [formValidationState, onFieldStateChange] = useValidationState({
		fields: {
			[Fields.LastName]: {
				error: false,
				valid: false
			},
			[Fields.TicketNumber]: {
				error: false,
				valid: false
			}
		}
	});

	const ticketNumberValidationState: FieldValidationState = formValidationState.fields[Fields.TicketNumber];
	const lastNameValidationState: FieldValidationState = formValidationState.fields[Fields.LastName];
	const isFormValid = ticketNumberValidationState.valid && lastNameValidationState.valid;

	const onSubmit: React.FormEventHandler = e => {
		if (!isFormValid) {
			return;
		}

		e.preventDefault();

		const ticketNumber = (e.currentTarget.querySelector('input[name="ticketNumber"]') as HTMLInputElement).value;
		const lastName = (e.currentTarget.querySelector('input[name="lastName"]') as HTMLInputElement).value;

		window.open(`https://booking.uvtaero.ru/websky/#/search-order/${ticketNumber}/${lastName}`, "_self");
	};

	return (
		<div className="search-order">
			<h4 className="search-order__heading">{i18n('searchOrderHeading')}</h4>
			<form onSubmit={onSubmit} className="search-order__form">
				<label className="search-order__label">
					{ticketNumberValidationState.error && (
						<span className="search-order__tooltip">
							{i18n(ticketNumberValidationState.error as string)}
						</span>
					)}
					<Input
						className={cn('search-order__input', {
							'search-order__input_error': ticketNumberValidationState.error
						})}
						name="ticketNumber"
						placeholder={i18n('ticketNumberPlaceholder')}
						fieldName={Fields.TicketNumber}
						onValidationStateChange={onFieldStateChange}
						validation={[correctPnrOrTicketNumber]}
					/>
				</label>
				<label className="search-order__label">
					{lastNameValidationState.error && (
						<span className="checkin-form__tooltip">{i18n(lastNameValidationState.error as string)}</span>
					)}

					<Input
						className={cn('search-order__input', {
							'search-order__input_error': lastNameValidationState.error
						})}
						name="lastName"
						placeholder={i18n('lastNamePlaceholder')}
						fieldName={Fields.LastName}
						onValidationStateChange={onFieldStateChange}
						validation={[correctLastName]}
					/>
				</label>
				<input
					className={cn('search-order__submit', { 'search-order__submit_disabled': !isFormValid })}
					disabled={!isFormValid}
					type="submit"
					value={i18n('searchOrder')}
				/>
			</form>
		</div>
	);
};

export default SearchForm;
