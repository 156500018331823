import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { SystemState } from '@nemo.travel/search-widget';
import { Language } from './schemes/';
import { i18nInit } from './i18n';

export const init = (config: SystemState) => {
	if (!config.rootElement) {
		throw Error('Please specify `rootElement` parameter in the configuration object.');
	}

	if (!config.nemoURL) {
		throw Error('Please specify `nemoURL` parameter in the configuration object.');
	}

	// Fix ukrainian language code.
	if ((config.locale as string).toLocaleLowerCase() === 'ua') {
		config.locale = Language.Ukrainian;
	}

	i18nInit(config.locale as string);

	ReactDOM.render(<App {...config} />, config.rootElement);
};
